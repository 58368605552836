/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import { Input, Dropdown, Form, Radio } from 'semantic-ui-react';
import { mdiClose, mdiEmail, mdiPlayBox, mdiClipboardCheck,mdiListBox } from '@mdi/js';
import Modal from 'react-modal';
import { AccountContext } from '../../providers/AccountProvider';
import Global from '../../clients/global';
import FieldButton from './FieldButton';
import IconButton from '../Block/components/FilterData/IconButton';
import TextButton from '../../components/TextButton/TextButton';
import TextControl from 'src/components/TextControl/TextControl';
import GBButton from '../../components/GBButton/GBButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import CodeEditor from 'src/components/CodeEditor/CodeEditor';
import EmailControl from '../Block/components/MessageView/EmailControl';
import RelationalDropdown from './RelationalDropdown';
import EvaluateFunction from './EvaluateFunction';
import { getAllForms, getForm, saveForm } from '../../api/forms';
import { getTableAPI} from '../../api/tables';
import UpdateRecords from '../ApiDocs/table/updateRecords';

const ActionFormTask = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [insertFields, setInsertFields] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [forms, setForms] = useState([]);
  const [fromAddresses, setFromAddresses] = useState([
    {
      key: '1',
      value: 'clientemaildelivery@graceblocks.com',
      text: 'clientemaildelivery@graceblocks.com',
    },
  ]);
  const [fromAddress,setFromAddress] =useState('clientemaildelivery@graceblocks.com')
  const [formData, setFormData] = useState(null);
  const [selectedForm, setSelectedForm] = useState('');
  const [overdue, setOverDue]=useState(localData.overdue ?? 3);
  const [reminder, setReminder]=useState(localData.reminder ?? 1)
  const [unFinished, setUnFinished]=useState(localData.unfinished ?? 6);
  const [sendReminders, setSendReminders] = useState(localData.sendReminders ?? true);
  const [emailFields, setEmailFields] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(localData.selectedEmail ?? 'manual');
  const [emailValue, setEmailValue] = useState(localData.emailValue ?? '');
  const [enableRecordUpdate, setEnableRecordUpdate] = useState(localData.enableRecordUpdate ?? false);
  const [refreshDate, setRefreshDate] = useState(Date.now());
  const [emailMessage, setEmailMessage] = useState({
    subject: 'Form required',
    message: `
      <div>
        Greetings,<br/><br/>
        You have an {{taskStatus}} task. Click the link below to complete this task.<br/><br /> 
        {{taskLink}} <br/><br/>
        Thank you.
      </div>
    `,
    fromAddress:'clientemaildelivery@graceblocks.com',
    fromName:'GraceBlocks Support',
    replyTo:'support@graceblocks.com'
  });

  useEffect(() => {
    loadForms();
  }, []);

  const editableFields =[1,2,3,4,5,6,7,8,9,10,11,13,14,22,23]

  const loadForms = async () => {
    let formResult = await getAllForms();

    const options = [];
    let aFields = [];

    formResult.map((el) => {
      options.push({
        key: el.formid.toString(),
        value: el.formid,
        text: `${el.blockname} - ${el.tablename}: (${el.formname})`,
        tableid: el.id
      });
    });

    if(localData.selectedForm !==undefined) {
      const formTemp = await getForm(localData.selectedForm);
    
        setSelectedForm(localData.selectedForm)
        setFormData(formTemp[0])

        const tblData = await getTableAPI(formTemp[0].tableid);
        // const editableFields =[1,2,3,4,5,6,7,8,9,10,11,13,14,22,23]

        tblData.tableinfo.columns
        .filter((fld) => editableFields.includes(fld.uitype))
        .map((col) => {
          aFields.push({ key: col.data, value: col.data, text: col.header,colInfo:col });
        });
      
        aFields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
        setAvailableFields(aFields);
    }

    setForms(options);
    getEmailandCollaboratorFields();

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);
      const tempFields = localData.insertFields.map(itm=>itm.value);
      aFields = aFields.filter(col => !tempFields.includes(col.value));
      setAvailableFields(aFields);
    }

    const currentZone = userInfo.zones.filter((el) => el.id === parseInt(Global.zoneid))[0];
    let tempAddresses = [
      {
        key: '1',
        value: 'clientemaildelivery@graceblocks.com',
        text: 'clientemaildelivery@graceblocks.com',
      },
    ];
    if (currentZone.attributes.fromAddresses) {
      currentZone.attributes.fromAddresses.map((el) => {
        if (el.isActive) {
          tempAddresses.push({ key: el, value: el.email, text: el.email });
        }
      });
      setFromAddresses(tempAddresses);
    }

    if(localData.emailMessage !==undefined) {
      setEmailMessage(localData.emailMessage)
    }

  };

  const updateableForms = () =>{
    const getDefinedtables = sourceNodes.filter(itm=>itm?.data?.tableData?.id !==undefined).map(itm=>itm.data.tableData.id);
    
    let formsTemp = forms.filter(frm=>getDefinedtables.includes(frm.tableid))
    return formsTemp
  }

  const updateRecordSetting = () =>{
    setEnableRecordUpdate(!enableRecordUpdate)
    setInsertFields([]) //we set insertFields to null, because you can't map in fields when id is passed in.
    localData.enableRecordUpdate = !enableRecordUpdate
    localData.insertFields=[];
    updateData(localData)
  }

  const updateEmailValue = (val) => {
    setEmailValue(val);
    localData.emailValue = val;
    if(localData.selectedEmail===undefined) {
      localData.selectedEmail='manual';
    }
    updateData(localData);
  };

  const updateOverDue = (val) =>{
    setOverDue(val);
    localData.overdue = val;
    updateData(localData)
  }

  const updateSendReminders =(val) =>{
    setSendReminders(val);
    localData.sendReminders =val;
    updateData(localData);
  }

  const updateReminder = (val) =>{

    setReminder(val);
    localData.reminder = val;
    updateData(localData)
  }

  const updateUnfinished = (val) =>{
    setUnFinished(val);
    localData.unfinished = val;
    updateData(localData)
  }

  const updateFromAddress = (val) =>{
    const copyTask = structuredClone(emailMessage);
    copyTask.fromAddress =val;
    setFromAddress(val)
    setEmailMessage(copyMessage)
    localData.emailMessage =emailMessage
    updateData(localData)
  }

  const getEmailandCollaboratorFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    const allOptions =[{ key: '0', value: '', text: 'Choose field' }];
    
    sourceNodes.map((node) => {
      node.data.insertMultiple==null && node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            (el.uitype == 8 && el.lookup === 'single') ||
            (el.source?.lookupuitype === 8 &&
              el.lookup === 'single' &&
              el.source.lookup === 'single') ||
            el.uitype === 10 ||
            (el.source?.lookupuitype === 10 && el.source.lookup === 'single'),
        )
        .map((col) => {
          const idx = emailOptions.findIndex(itm=>itm.key===`${node.data.tableData.id}|${col.data}`)
          if(idx ===-1) {
            emailOptions.push({
              key: `${node.data.tableData.id}|${col.data}`,
              value: `${node.data.tableData.id}|${col.data}`,
              text: `${node.data.tableData.name} (${col.header})`,
            });
        }
        });
    });

    sourceNodes.map((node) => {
      node.data.insertMultiple==null && node.data?.tableData?.tableinfo?.columns
        .map((col) => {
          const idx = allOptions.findIndex(itm=>itm.key===`${node.data.tableData.id}||${col.data}`)
          if(idx === -1) {
          allOptions.push({
            key: `${node.data.tableData.id}||${col.data}`,
            value:`${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
            text: `${node.data.tableData.name}||${col.header}`,
          });
        }
        });
    });

    setEmailFields(emailOptions);
    setAllFields(allOptions)

  };

  const handleEmailSelection = (val) => {
    setSelectedEmail(val);
    localData.selectedEmail = val;
    updateData(localData);
  };

  const configureEmail = (
    emailObjectArray,
    messageSetting,
    templateFields,
    ids,
    blockRoles,
    role,
  ) => {
    const copyTask = structuredClone(emailMessage);
    copyTask.message = templateFields.filter(el=>el.field==='message')[0].fieldValue;
    copyTask.fromName = templateFields.filter(el=>el.field==='fromName')[0].fieldValue;
    copyTask.subject = templateFields.filter(el=>el.field==='subject')[0].fieldValue;
    copyTask.replyTo = templateFields.filter(el=>el.field==='replyTo')[0].fieldValue;
    if(templateFields.filter(el=>el.field==='CC')[0].fieldValue !=='') {
        copyTask.CC = templateFields.filter(el=>el.field==='CC')[0].fieldValue;
    }
    if(templateFields.filter(el=>el.field==='BCC')[0].fieldValue !=='') {
        copyTask.BCC = templateFields.filter(el=>el.field==='BCC')[0].fieldValue;
    }
   
    setEmailMessage(copyTask)
    localData.emailMessage = copyTask
    updateData(localData);

    setModalIsOpen(false);
  };

  const handleFormSelection = async (formid) => {
    const form = await getForm(formid);
    setSelectedForm(formid);
    setFormData(form[0]);
    localData.selectedForm = formid;

    localData.enableRecordUpdate = enableRecordUpdate;

    //10-8-24 removed check on wehther enableRecordUpdate. We'll just always add System ID
    //as duplicate field check, as this should always be the case anyway.
      if(
        form[0].settings && // Check if settings exists
        form[0].settings.duplicateFields &&
        form[0].settings?.duplicateFields?.findIndex(itm=>itm.includes('id')) ===-1) 
        {
           //add the id field as duplicate field for this form. 
           if(form[0].settings.duplicateFields ===undefined) {
            form[0].settings.duplicateFields=[['id']]
           } else {
            form[0].settings.duplicateFields.push(['id'])
           }
           await saveForm(form[0]);
        }
    
    const tblData = await getTableAPI(form[0].tableid);
    
    //7-16-24 By default, there are settings which are not saved on the node. Here, we check
    //and if they don't yet exist, we'll set them to default values.
    if(localData.reminder===undefined) {
      localData.reminder=1;
    } 
    if(localData.overdue===undefined) {
      localData.overdue = 3;
    }
    if(localData.unfinished ===undefined) {
      localData.unfinished=6;
    }
    
    const aFields = [];
    tblData.tableinfo.columns
      .filter((fld) => editableFields.includes(fld.uitype))
      .map((col) => {
        aFields.push({ key: col.data, value: col.data, text: col.header,colInfo:col});
      });
      aFields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    setAvailableFields(aFields);

    delete localData.availableFields;
    delete localData.insertFields;
    
    //7-16-24 When we select a form, we need to also get the associated tableData, as this
    //needs to be used/referenced in downstream actions.
    localData.tableData = tblData;
    updateData(localData);
  };

  const addRemoveField = (value) => {
    const idx = insertFields.findIndex((el) => el.value === value);
    const idx2 = availableFields.findIndex((el) => el.value === value);

    let tempInsert = structuredClone(insertFields);
    let tempAvailable = structuredClone(availableFields);

    if (idx === -1) {
      const fld = availableFields[idx2];
      fld.mappedValue = 'manual';
      tempInsert.push(fld);
      tempAvailable = tempAvailable.filter((itm) => itm.value !== value);
    } else {
      const fld = insertFields[idx];
      fld.mappedValue = 'manual';
      tempAvailable.push(fld);
      tempInsert = tempInsert.filter((itm) => itm.value !== value);
    }

    setInsertFields(tempInsert);
    setAvailableFields(tempAvailable);

    localData.insertFields = tempInsert;
    localData.availableFields = tempAvailable;

    updateData(localData);
  };

  const getOptions = (colInfo) => {
    const options = [
      { key: '0', value: 'manual', text: 'Manual entry' },
      { key: 'function', value: 'function', text: 'Javascript function' },
    ];

    sourceNodes.map((node) => {

      let columns =  node.data.tableData?.tableinfo.columns;
      
      
      if(colInfo) {
        columns =  node.data.tableData?.tableinfo.columns.filter(fl=>![1,6,7].includes(fl.uitype) && fl.uitype===colInfo.uitype);
        //If there is a defined single lookup, only retrieve source fields which are also single.
        if(colInfo.lookup ==='single') {
          columns = columns.filter(itm=>itm.lookup==='single')
        }
      }

      node.data.insertMultiple==null && columns && columns.map((col) => {
        const idx = options.findIndex(itm=>itm.key===`${node.data.tableData.id}|${col.data}`)
        if(idx===-1) {
          options.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
          });
        }
      });
    });

    return options;
  };

  const getMappedValue = (field, attribute) => {
    const idx = insertFields.findIndex((col) => col.key === field);
    if (idx !== -1) {
      return insertFields[idx][attribute] ?? false;
    }
  };

  const handleMappedValueChange = (field, val, attribute) => {
    const idx = insertFields.findIndex((col) => col.value === field);
    if(idx !==-1) {
      const temp = structuredClone(insertFields);
      temp[idx][attribute] = val;
      setInsertFields(temp);
      localData.insertFields = temp;
      updateData(localData);
    }
  };

  const updateFieldValue = (field, value) => {
    const idx = insertFields.findIndex((el) => el.key === field);
    if (idx !== -1) {
      insertFields[idx].dataValue = value;
      setInsertFields(insertFields);

      localData.insertFields = insertFields;
      updateData(localData);
      setRefreshDate(Date.now());
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        <div>
          <div style={{ marginTop: '5px', marginBottom: '5px' }}>
            From address:{' '}
            <Dropdown
              selectOnBlur={false}
              fluid
              value={fromAddress}
              selection
              options={fromAddresses}
              onChange={(e, data) => updateFromAddress(data.value)}
            />
          </div>
        </div>
        <div>
          Assigned to <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          selectOnBlur={false}
          options={emailFields}
          value={selectedEmail}
          selection
          fluid
          onChange={(e, data) => handleEmailSelection(data.value)}
        />
        {selectedEmail === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={emailValue}
              fluid
              label="Email"
              onChange={(e, data) => updateEmailValue(data.value)}
            />
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: '10px' }} />
     

      <div style={{ marginTop: '10px' }}>
        <div>Form</div>
        <div style={{marginBottom:'10px'}}>
        <GBSwitch 
          text="Enable record updates"
          isChecked={enableRecordUpdate}
          Action={updateRecordSetting} />
        </div>
        <Dropdown
          selectOnBlur={false}
          search
          value={selectedForm}
          selection
          options={ enableRecordUpdate ? updateableForms()  : forms}
          fluid
          onChange={(e, data) => handleFormSelection(data.value)}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        {!enableRecordUpdate ? (
        <FieldButton
          options={availableFields}
          addRemoveField={addRemoveField}
          label="Configure web form field pre-sets"
          icon={mdiListBox}
        />):null}

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {insertFields.length > 0
            ? insertFields.map((fld) => (
                <div
                  style={{
                    margin: '10px',
                    padding: '10px',
                    border: '1px solid black',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: '10px' }}>{fld.text}</div>
                    <Dropdown
                      selectOnBlur={false}
                      fluid
                      search
                      value={getMappedValue(fld.key, 'mappedValue')}
                      selection
                      options={getOptions(fld.colInfo)}
                      onChange={(e, data) =>
                        handleMappedValueChange(fld.key, data.value, 'mappedValue')
                      }
                    />
                    <IconButton
                      color="red"
                      hoverColor="white"
                      hoverBackgroundColor="#F6685E"
                      icon={mdiClose}
                      Action={addRemoveField}
                      ActionValue={fld.value}
                    />
                  </div>
                  {getMappedValue(fld.key, 'mappedValue') === 'manual' && ![1,4].includes(fld.colInfo?.uitype) && formData !==null ? (
                    <div style={{ marginTop: '5px' }}>
                    <TextControl
                      value={fld.dataValue}
                      key={fld.key}
                      showLabel={false}
                      index={0}
                      alignControl={true}
                      blockid={formData.blockid}
                      updateValue={updateFieldValue}
                      tableid={formData.tableid}
                      colInfo={fld.colInfo}
                      role={3}
                      canEdit={true}
                      editMode={true}
                    />
                  </div>
                  ) : null}

                {getMappedValue(fld.key, 'mappedValue') === 'manual' && fld.colInfo?.uitype===1 ? (
                      // <TextControl index={0} alignControl={true} key={'dddd'} blockid={selectedBlock} updateValue={updateFieldValue} tableid={localData.tableData.id} value={null} colInfo={fld.colInfo} role={3} canEdit={true} editMode={true} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <RelationalDropdown
                          tableid={formData.tableid}
                          colInfo={fld.colInfo}
                          selectedOptions={fld.dataValue}
                          updateFieldValue={updateFieldValue}
                        />
                      </div>
                    ) : null}
                  {getMappedValue(fld.key, 'mappedValue') === 'function' ? (
                    <>
                      <div style={{ marginTop: '10px' }}>
                        <CodeEditor
                          fields={getOptions().map((el) => el.text)}
                          updateFormula={updateFieldValue}
                          formula={fld.dataValue}
                          isReadOnly={false}
                          mode="javascript"
                          field={fld.key}
                        />
                      </div>
                      <div>
                        <TextButton
                          tooltip='Enter sample values into the token fields for this function and click the play icon to see what will return as the result.'
                          text="Evaluate"
                          textColor="#0D99FF"
                          color="#0D99FF"
                          hoverColor="#0D99FF80"
                          icon={mdiClipboardCheck}
                          Action={() => {
                            handleMappedValueChange(
                              fld.value,
                              !getMappedValue(fld.key, 'evaluateFunction'),
                              'evaluateFunction',
                            );
                          }}
                          iconSize="30px"
                          iconPosition="left"
                        />
                      </div>
                      {getMappedValue(fld.key, 'evaluateFunction') == true ? (
                        <div>
                          <EvaluateFunction functionString={fld.dataValue} />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ))
            : null}
        </div>

        <div style={{ marginTop: '20px' }}>
          <b>Notifications</b>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',marginTop:'10px' }}>
            <div style={{ marginRight: '10px' }}>Initial email</div>
            <div>
              <GBButton
                text="Configure email"
                icon={mdiEmail}
                iconSize="20px"
                iconColor="white"
                iconHoverColor="white"
                padding="10px"
                iconPosition="left"
                borderRadius="10px"
                Action={() => setModalIsOpen(true)}
                ActionValue={'trigger'}
                textColor="white"
                width="maxWidth"
                // height={'30px'}
                color="#0D99FF"
                fontWeight='normal'
                textHoverColor="white"
                // hoverBackgroundColor={'#0D99FF80'}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
            <GBSwitch  text="Send reminders"
                isChecked={sendReminders}
                Action={updateSendReminders} />
                
            <div style={{height:'10px'}}></div>
            Send reminder every <Input type="number" min="1" style={{width:'70px'}} value={reminder} onChange={(e,data)=>updateReminder(data.value)}  /> days.
            <br/><br/>
            This task is overdue after <Input type="number" style={{width:'70px'}} value={overdue} onChange={(e,data)=>updateOverDue(data.value)} /> days.     
            <br/><br/>
            Mark task as unfinished if not submitted <Input  type="number" style={{width:'70px'}} value={unFinished} onChange={(e,data)=>updateUnfinished(data.value)}  /> days.
        </div>

      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: 'fixed',
            zIndex:100,
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            border: `.3px solid #0D99FF`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 6010,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
          },
        }}
        contentLabel="Example Modal"
      >
        <EmailControl
          toAddress={'{{dynamic}}'}
          tableid={sourceNodes.filter(fl=>fl.type==='trigger')[0].data?.tableData?.id}
          emailField={
            {fromAddress:emailMessage.fromAddress, 
              replyTo: emailMessage.replyTo, 
              fromName:emailMessage.fromName,
              CC: emailMessage.CC,
              BCC: emailMessage.BCC
            }
            }
          replyMsg={emailMessage}
          color="#0D99FF"
          sendMessage={configureEmail}
          close={() => setModalIsOpen(false)}
          role={3}
          tokens={allFields}
        />
      </Modal>
    </div>
  );
};

export default ActionFormTask;
